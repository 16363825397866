import React from 'react';
import styled from 'styled-components';

import SEO from 'components/SEO';

const Tel = styled.span`
  display: block;
  margin: 2em;
  color: #4a66a5;
  font-size: 1.6em;
  font-weight: bold;
}
`;

const UeberUnsPage = () => (
  <>
    <SEO title="Über uns" keywords={[]} />
    <h1>Über uns</h1>

    <p>Hinter der Jomag AG steht der Name Erich Mathys.</p>

    <p>
  Die Jomag AG hat seit der Gründung im Jahre 1986 mit Erfolg nach
  Lösungen gesucht und gefunden, wo eine Abdichtung, Sanierung oder ein
  Fugenproblem aufgetaucht ist. Über die Jahre haben wir uns im Bausektor auf
  diverse Abdichtungsarten spezialisiert, sei es im Hoch-, Tief-, Fassaden-
  oder Fensterbau.
    </p>

    <p>Brauchen Sie eine dichte Lösung? Rufen Sie uns an!</p>

    <p>
      <Tel>+41&nbsp;31&nbsp;722&nbsp;0706</Tel>
    </p>

  </>
);

export default UeberUnsPage;
